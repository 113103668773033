@font-face {
  font-family: "Nantes";
  src: url("./fonts/NantesWeb-Regular.woff2") format("woff2"),
    url("./fonts/NantesWeb-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Nantes";
  src: url("./fonts/NantesWeb-SemiBold.woff2") format("woff2"),
    url("./fonts/NantesWeb-SemiBold.woff") format("woff");
  font-style: medium;
  font-weight: 500;
  font-display: swap;
}

* {
  margin: 0;
  box-sizing: border-box;
  -webkit-appearance: none;
  /* font-family: "Inter", -apple-system, sans-serif; */
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
}

a {
  -webkit-tap-highlight-color: transparent;
}

/*** spinner ****/

.lds-ring {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  margin: 3px;
  border: 3px solid #000;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/***** */

div.spinner {
  height: 21px;
  width: 21px;
  margin: 0px auto;
  border-radius: 50%;
  border: dotted 2px #03e429;
  animation-name: loader-spin;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

div.spinner.large {
  height: 31px;
  width: 31px;
  margin: 0px auto;
  border-radius: 50%;
  border: dotted 3px #03e429;
}

@keyframes loader-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* tooltip */

.__react_component_tooltip {
  padding: 4px 6px !important;
  display: flex !important;
  align-items: center;
  font-size: 14px;
}

.mstyle {
  /* padding: 2px 4px !important; */
  /* border-radius: 2px; */
  /* background: #ededed; */
}

.mrow {
  /* background: #ededed; */
  /* padding: 2px 4px !important; */
}

.MathJax span {
  /* padding: 4px !important; */
  font-size: 15px;
  color: black !important;
  /* border-radius: 2px; */
  /* height: 24px; */
}

.MathJax {
  /* padding: 2px 4px !important;
  background: #f2f2f2; */
}

.math {
  padding: 0px 4px !important;
  background: #f2f2f2;
  border-radius: 2px;
}

#MathJax_Message {
  display: none !important;
}
