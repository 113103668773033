/* pdf viewer styles */

.Viewer {
  background-color: #f2f2f2;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: scroll;
  padding-top: 16px;
  padding-bottom: 16px;

  /* testing colors; meh i think too distracting */
}

.pdfViewer {
  padding-bottom: -15vh;
  /* margin-bottom: -15vh; */
}

.pdfViewer .page {
  border: none !important;
  margin-bottom: 16px !important;
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.1) !important;
}

/* .pdfViewer .canvasWrapper {
  border-radius: 4px;
} */

.linkAnnotation {
  border: none !important;
  border-bottom: solid 1px blue !important;
  /* text-decoration: underline !important; */
  display: none !important;
}

.linkAnnotation:hover {
  border-bottom: solid 2px blue !important;
}

@media (max-width: 1080px) {
  .Viewer {
    max-height: 100vh;
    padding-bottom: 0;
  }
}
